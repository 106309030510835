import {
    SET_STOCKIST,
    CLEAR_STATE,
    SET_STATE_DATA,
    SET_ALL_STOCKISTS
} from '../types/Stockist.type'

const initState = {
    stockistdetails: {},
    token: null,
    stockistState: {
        stockistdetails: null,
        stockistid: "",
        stockistList: [],
        stockistinfo: "",
        stockistdetailsList: [],
        countries: [],
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        currentDate: new Date(),
        doctorList: [],
        isSearch: false,
        isShowCancelModal: false,
        cancelOrderId: null,
        cancelReason: "",
        mobileno: "",
        summaryCols: ["Total no. of Registration", "Total no. of Order", "Total order Fulfilled", "Payment Process", "Order Pending", "Order Delivered"],
        summaryDetails: null,
        setorderStatus: "",
        exportData: null,
        fileName: "Stockist_Data",
        allStockists: []
    }
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_STOCKIST:
            return Object.assign({}, state, { stockistdetails: { ...payload } })
        case SET_STATE_DATA:
            return Object.assign({}, state, { stockistState: payload })
        case SET_ALL_STOCKISTS:
            return Object.assign({}, state, { allStockists: payload })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}