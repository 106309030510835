// import React, { Component } from 'react'
import React, { Component, useState, useRef } from 'react';
import { connect } from "react-redux";
import OtherService from '../../services/OtherService';
import style from "./Login.style";
import CustomerService from "../../services/Customer.service";
import { withRouter } from "../../components/Reusable/WithRouter";
import CustomerAction from "../../redux/actions/Customer.action";
import CommonAction from '../../redux/actions/Common.action';
import Common from '../../hoc/Common.hoc';
import TimerComponent from '../../components/Timer/LoginTimer';
import { error } from 'jquery';
import config from '../../config/emrok.config';
import ContactUsComponent from '../../components/Common/ContactUs.component';

class Login extends Component {
    constructor(props) {
        super(props)
        this.firstRef = React.createRef();
        this.secondRef = React.createRef();
        this.thirdRef = React.createRef();
        this.fourthRef = React.createRef();
        // this.inputRefs = []; // Array to store input field references

        this.state = {
            mobileno: "",
            isMobileVerified: false,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            // currentInputIndex: 0,
            combineOtpValidLength: ""
        }
        console.log(props)
    }

    componentDidMount() {
        console.log(this.props, 333330)
        console.log(this.props.location.pathname, 333330);
    }
    /**
     * 
     * @param {Object} event 
     */
    setNumber = (event) => {
        const val = event.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!val || (reg.test(val) && val.length <= 10)) {
            this.setState({ mobileno: val })
        }
    }

    verifyMobileSubmit = (e) => {
        e.preventDefault();
        const { mobileno } = this.state;
        const data = { mobileno }
        if (mobileno.length == 10) {
            OtherService.generateOtp(data).then(res => {
                console.log(res)
                if (res && res.data && res.data.success) {
                    this.setState({ isMobileVerified: true })
                }
            }).catch(err => {
                console.log(err)
            });
        }

    }

    mobileVerify = () => {
        const { mobileno } = this.state;
        const isValid = mobileno.length == 10;
        return (
            <section className="login-part-st">
                <div className="container">

                    <div className="login-form">
                        <div className="muown-logo">
                            <img src={require("../../assets/images/dgf-logo.png")} />
                        </div>

                        <h2 className="pb-3">Welcome to Diabetes Golden Forum</h2>

                        <div className="mobile-inpt-pt pt-3">
                            <form onSubmit={e => { e.preventDefault(); }}>
                                <div className="cl-inpt-part-st"><input onChange={(event) => {
                                    this.setNumber(event)
                                }} value={mobileno} type="text" className="form-control mbl-inpt-part" placeholder="Enter Mobile Number" /></div>
                            </form>
                        </div>

                        <div className="know-mr-btn pt-3">
                            {/* <p><a href={config.knowMoreLink} target="_blank" rel="noopener noreferrer">Know more</a></p> */}
                        </div>

                        <div className="pt-3">
                            <a type="button" disabled={true} style={{ cursor: isValid ? "pointer" : "default" }} className={isValid ? "login-btn-usr" : "login-btn-usr login-disable"} onClick={this.verifyMobileSubmit}>Login</a>
                        </div>
                        <div className='pt-3'>
                            <ContactUsComponent />
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    combineOtp = () => {
        const { otp1, otp2, otp3, otp4 } = this.state;
        const otp = otp1 + otp2 + otp3 + otp4;
        return otp;
    }
    changeOtp = (event, ref) => {
        const { name, value } = event.target;
        console.log(this.props.hoc.numbericValidator(value), name, value, 5555555)
        if (!this.props.hoc.numbericValidator(value)) {
            this.setState({ [name]: "" })
            return false
        }
        else {
            const callBack = () => {
                const otp = this.combineOtp();
                if (otp.length == 4) {
                    this.setState({ combineOtpValidLength: true })
                }
                else {
                    this.setState({ combineOtpValidLength: false })
                }
                if (ref && value) {
                    ref.focus()
                }
            };
            if (value.length <= 1) {
                this.setState({ [name]: value }, callBack)
                if (value === "" && ref && ref.current) {
                    const prev = ref.current.previousSibling;
                    if (prev) {
                        prev.focus();
                    }
                }
            }
        }
    }


    handleBackspace = (index, event) => {
        const { otp1, otp2, otp3, otp4, combineOtpValidLength } = this.state;
        console.log(event.target.name, 56666);
        if (event.key === 'Backspace') {
            // Move focus to the previous input field
            if (index > 0) {
                const prevInput = document.getElementById(`otp-transpr-pt-${index - 1}`);
                prevInput.focus();
            }
            if (event.target.name == 'otp4') {
                const name = event.target.name;
                this.setState({ [name]: '' })
            }

            // event.target.value += ' ';
        }
    };

    verifyOtpSubmit = () => {
        const otp = this.combineOtp();
        const mobileno = this.state.mobileno
        const data = {
            mobileno, otp
        }
        const custimerLogin = () => {

            const prescribedDoctor = this.props.param.prescribedDoctor;
            console.log(prescribedDoctor, mobileno);
            CustomerService.customerLogin({
                mobileno, prescribedDoctor
            }).then(async (res) => {
                console.log(res, 111);
                // return
                if (res && res.data && res.data.success && res.data.data) {
                    console.log(res.data.data._customer[0], 1112);

                    // if (Array.isArray(res.data.data._customer)) {
                    //     console.log("myVar is an array");
                    //     let customer = res.data.data._customer[0].customer;
                    //     this.props.setCustomer(customer)
                    // } else {
                    //     console.log("myVar is not an array");
                    // console.log(res.data.data._customer[0].doctor, 3333);
                    const doctorsLength = res.data.data._customer[0].doctors ? res.data.data._customer[0].doctors.length : null;
                    const allDoctors = res.data.data._customer[0].doctors ? res.data.data._customer[0].doctors[doctorsLength - 1] : null
                    console.log(allDoctors, 111);
                    const firstAddress = res.data.data._customer[0].address.length > 0 ? res.data.data._customer[0].address[0] : null;

                    const prescriptionLength = res.data.data._customer[0].prescription ? res.data.data._customer[0].prescription.length : null;
                    const allPrescription = res.data.data._customer[0].prescription ? res.data.data._customer[0].prescription[prescriptionLength - 1] : null;


                    let customerDetailsObj = {
                        _id: res.data.data._customer[0].customer._id,
                        customer_code: null,
                        country_code: "+91",
                        mobileno: res.data.data._customer[0].customer.mobileno,
                        age: res.data.data._customer[0].customer.age,
                        email: res.data.data._customer[0].customer.email,
                        gender: res.data.data._customer[0].customer.gender,
                        name: res.data.data._customer[0].customer.name,
                        drName: allDoctors ? allDoctors.drName : "",
                        drRegistrationNo: allDoctors ? allDoctors.drRegNo : "",
                        drPin: allDoctors ? allDoctors.pin : "",
                        drmobileno: allDoctors ? allDoctors.mobileno : "",
                        drState: allDoctors ? (allDoctors.drState ? allDoctors.drState : "") : "",
                        drCity: allDoctors ? (allDoctors.drCity ? allDoctors.drCity : "") : "",
                        address: {
                            _id: firstAddress ? firstAddress._id : "",
                            country: firstAddress ? firstAddress.country : "",
                            addressLine1: firstAddress ? firstAddress.addressLine1 : "",
                            addressLine2: firstAddress ? firstAddress.addressLine2 : "",
                            town: firstAddress ? firstAddress.town : "",
                            state: firstAddress ? firstAddress.state : "",
                            pin: firstAddress ? firstAddress.pin : "",
                        },
                        doctor_id: allDoctors ? allDoctors._id : "",
                        consent: res.data.data._customer[0].customer.consent ? res.data.data._customer[0].customer.consent : false,
                        prescription: allPrescription
                    };
                    console.log(customerDetailsObj, 111);

                    this.props.setCustomer(customerDetailsObj)
                    // }


                    console.log(res.data.data.token, 1112);
                    // setTimeout(() => {

                    console.log("Current location:", this.props.location);

                    this.props.setToken(res.data.data.token)
                    this.props.navigate("/registration")

                    // }, 500);

                }
            }).catch(err => {
                this.props.hoc.customAlert("Something went wrong.", false)
                console.log(err)
            })


        }
        OtherService.verifyOtp(data).then(res => {
            console.log(res, 7878);

            if (res && res.data && res.data.success) {
                // const drId = this.props.param.dr_id;
                custimerLogin();

            }
            else if (res && res.data && !res.data.success) {
                this.props.hoc.customAlert(res.data?.message || "Something went wrong.", false)
            }
        }).catch(err => {
            console.log(err)
        })


    }


    otpVerify = () => {
        const { mobileno, otp1, otp2, otp3, otp4, combineOtpValidLength } = this.state;
        const resendOtp = () => {

            const { mobileno } = this.state;
            const data = { mobileno }
            if (mobileno.length == 10) {
                OtherService.generateOtp(data).then(res => {
                    console.log(res)
                    if (res && res.data && res.data.success) {
                        this.setState({ isMobileVerified: true })
                    }
                }).catch(err => {
                    console.log(err)
                });
            }
        }

        const inputfocus = (elmnt) => {
            if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
                console.log(elmnt.target.value)
                const tabindex = elmnt.target.tabIndex;

                const prev = elmnt.target.tabIndex - 2;
                console.log(prev);
                if (prev > -1) {

                    elmnt.target.form.elements[prev].focus()
                }
            }
            else {
                console.log("next");

                const next = elmnt.target.tabIndex - 1;
                console.log(next);
                if (next < 4) {
                    elmnt.target.form.elements[next].focus()
                }
            }
        }


        return (
            <section className="login-part-st">
                <div className="container">

                    <div className="login-form">
                        <div className="muown-logo">
                            <img src={require("../../assets/images/dgf-logo.png")} />
                        </div>

                        <h2 className="pb-3">Welcome to Diabetes Golden Forum</h2>
                        <h1 className="otp-part-pt otpsent">OTP SENT</h1>
                        <p className="otp-text-pt">Enter the OTP sent to you</p>

                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="otp-clm-gap d-flex mb-3 mt-3">
                                <div className="">
                                    <input type="text" inputMode="numeric" ref={input => { this.firstRef = input }} id="otp-transpr-pt-0" onChange={(event) => this.changeOtp(event, this.secondRef)} value={otp1} name="otp1" maxLength="1" onKeyDown={e => this.handleBackspace(0, e)} tabIndex="1" />
                                </div>
                                <div className="">
                                    <input type="text" inputMode="numeric" ref={input => { this.secondRef = input; }} id="otp-transpr-pt-1" onChange={(event) => this.changeOtp(event, this.thirdRef)} onKeyDown={e => this.handleBackspace(1, e)} value={otp2} name="otp2" maxLength="1" tabIndex="2" />
                                </div>
                                <div>
                                    <input type="text" inputMode="numeric" ref={input => { this.thirdRef = input; }} id="otp-transpr-pt-2" onChange={(event) => this.changeOtp(event, this.fourthRef)} onKeyDown={e => this.handleBackspace(2, e)} value={otp3} name="otp3" maxLength="1" tabIndex="3" />
                                </div>
                                <div className="">
                                    <input type="text" inputMode="numeric" ref={input => { this.fourthRef = input; }} id="otp-transpr-pt-3" onChange={(event) => this.changeOtp(event)} value={otp4} onKeyDown={e => this.handleBackspace(3, e)} name="otp4" tabIndex="4" maxLength="1" />
                                </div>

                            </div>

                            <div className="resend-otp-fr-login">
                                <span> <TimerComponent resendOtp={resendOtp} /> </span>
                            </div>
                            <div className="mb-3 mt-4">
                                <a disabled={combineOtpValidLength ? false : true} style={combineOtpValidLength ? { ...style.btn } : null} className="form-control login-btn-usr" id="" onClick={() => this.verifyOtpSubmit()}> Done</a>
                            </div>
                            <div className='pt-3'>
                                <ContactUsComponent />
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        )
    }

    render = () => {

        const { isMobileVerified } = this.state;
        return (

            <>

                {
                    !isMobileVerified ? this.mobileVerify() : this.otpVerify()
                }
            </>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setToken: (token) => dispatch(CommonAction.setToken(token))
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Common(Login)))