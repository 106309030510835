

import ProductAction from '../redux/actions/Product.action';
import { store } from '../redux/index.store';
import { STRINGS } from '../utils/strings';
import Base from './Base.service';
import {
  GET_PRODUCT,
  GET_STOCKIST_CODE,
  GET_PRODUCT_BY_PLANT_CODE,
  GET_PRODUCT_BY_DOCTOR,
  GET_ALL_PRODUCTS_BY_MATERIAL,
  UPLOAD_PRODUCT_IMAGE,
  GET_ALL_PRODUCT,
  ADD_PRODUCT,
  GET_PRODUCT_LIST
} from './Slugs'


class ProductService extends Base {
  constructor(props) {
    super(props)
  }

  getProducts(data) {
    // console.log(data,"data")
    return this.get(GET_PRODUCT, data);
  }
  getProductsByPlantCode(data) {
    return this.post(GET_PRODUCT_BY_PLANT_CODE, data);
  }
  getProductList(data) {
    return this.post(GET_ALL_PRODUCT, data);
  }

  getStockistCode(data) {
    return this.post(GET_STOCKIST_CODE, data)
  }

  getAllProductsByDoctor(data) {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_PRODUCT_BY_DOCTOR);
        console.log(response.data, "getAllProducts");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setProducts(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }

  getAllProducts() {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_PRODUCT);
        console.log(response.data, "getAllProducts");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setProducts(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }

  getAllProductsByMaterial() {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_ALL_PRODUCTS_BY_MATERIAL);
        console.log(response.data, "getAllProductsByMaterial");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setProductsByMaterial(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }

  changeImage(data) {
    console.log(data, UPLOAD_PRODUCT_IMAGE, 77667766);
    return this.post(UPLOAD_PRODUCT_IMAGE, data, false)
  }




  //ayan
  getAllProduct() {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_ALL_PRODUCT);
        console.log(response.data, "getAllProduct");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setAllProduct(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }

  addProduct(payload) {
    return new Promise(async resolve => {


      try {
        let response = await this.post(ADD_PRODUCT, payload, false);
        console.log(response.data, "getAllProduct");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setAllProduct(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }
}

export default new ProductService()