import React from "react";

class Summary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      data: [],
      clickFn: undefined
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      columns: props.columns || [],
      data: props.data || [],
      clickFn: props.clickFn || undefined
    }
  }

  componentDidMount() {

  }

  render = () => {
    const { columns, data, clickFn } = this.state
    console.log(this.state, "opopopopo");
    

    return columns.length && data.length ? (
      <div className="summary-table mt-32 mb-4">
        <h5 className="mb-20">Summary</h5>
        {/* Table start */}
        <div className="table-responsive">
          <table className="table table-bordered-custom">
            <thead>
              <tr>
                {
                  columns.map((item, index) => {
                    return (
                      <th scope="col" key={`${index}-col`}>{item}</th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      {
                        Object.entries(item).map((subItem, i) => {
                          return (
                            <td key={`${i}-data-${index}`} style={{cursor: "pointer"}} onClick={() => {
                              if(clickFn) {
                                clickFn(i)
                              }
                            }}>{subItem[1]}</td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {/* Table end */}
      </div>
    ) : null
  }
}

export default Summary