import {
    SET_STOCKIST,
    CLEAR_STATE,
    SET_STATE_DATA,
    SET_ALL_STOCKISTS
    } from '../types/Stockist.type'
    class StockistAction {
      setStockist = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_STOCKIST, payload: data }
      }
      
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }

      setStateData = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_STATE_DATA, payload: data }
      }
     
      setAllStockists = data => {
        return { type: SET_ALL_STOCKISTS, payload: data }
      }
    }
    export default new StockistAction()