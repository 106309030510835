import {
  SET_DOCTOR_PERSONA,
  STORE_SEARCH_VALUE,
  LOGOUT
} from '../types/DoctorPersona.type'
class DoctorPersonaAction {
  setDoctorPersona = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_DOCTOR_PERSONA, payload: data }
  }

  setSearchValue = (data) => {
    // alert(data)
    return { type: STORE_SEARCH_VALUE, payload: data }
  }

  logout = () => {
    return { type: LOGOUT, payload: null }
  }


}
export default new DoctorPersonaAction()