import {
    SET_DOCTOR_PERSONA,
    STORE_SEARCH_VALUE,
    LOGOUT
} from '../types/DoctorPersona.type'

const initState = {
    doctorPersonaDetails: {},
    token: null,
    storeSearchData: {
        doctordetails: null,
        doctorid: "",
        doctordetailsList: [],
        doctorinfo: "",
        countries: [],
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        isSearch: false,
        setorderStatus: "",
        allStockists: [],
        storeSearchData: null,
        exportDropdown: [
            {
                id: 1,
                label: "Export Current View",
                value: 1
            },
            {
                id: 2,
                label: "Export All Data",
                value: 2
            }
        ],
        selectedExportItem: null,
    }
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_DOCTOR_PERSONA:
            return Object.assign({}, state, { doctorPersonaDetails: { ...payload } })
        case STORE_SEARCH_VALUE:
            return Object.assign({}, state, { storeSearchData: payload })
        case LOGOUT:
            return initState;
        default:
            return state
    }
}