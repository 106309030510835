import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import moment from "moment";
import AdminHeader from "../../components/layout/Admin/AdminHeader";
import AdminFooter from "../../components/layout/Admin/AdminFooter";
import AdminSidebar from "../../components/layout/Admin/AdminSidebar";
import AdminAction from "../../redux/actions/Admin.action";
import AdminService from "../../services/Admin.service";
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';

import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import ComparisonChart from "../../components/Common/ComparisonChart.component";

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            adminDashboardDetails: null,
            showChart: false,
            maxCityCardHeight: 0,
            topFiveDoctorCardHeight: 0,
            graphCardHeight: 0
        }
        this.maxCityCardRef = React.createRef()
        this.topFiveDoctorCardRef = React.createRef()
        this.graphCardRef = React.createRef()
    }

    static getDerivedStateFromProps(props, state) {
        return {
            adminDashboardDetails: props.adminDashboardDetails || null
        }
    }

    componentDidMount() {
        this.getDashBoardDetails()
    }

    getDashBoardDetails = () => {
        AdminService.getDashBoardDetails().then(data => {
            console.log(data, "getDashBoardDetails");
            // if (data.data.success && data.data.data.length) {
            // let payload = data.data.data[0]
            // this.props.setDashboardDetails(payload)
            // }

            this.adjustCardHeights();
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.adminDashboardDetails !== this.props.adminDashboardDetails) {
            this.adjustCardHeights();
        }
    }

    adjustCardHeights = () => {
        const maxCityCardHeight = this.maxCityCardRef.current ? this.maxCityCardRef.current.offsetHeight : 0;
        const topFiveDoctorCardHeight = this.topFiveDoctorCardRef.current ? this.topFiveDoctorCardRef.current.offsetHeight : 0;
        const graphCardHeight = this.graphCardRef.current ? this.graphCardRef.current.offsetHeight : 0;
        const maxHeight = Math.max(maxCityCardHeight, topFiveDoctorCardHeight);
        const extendedHeight = Math.max(maxHeight, graphCardHeight)

        this.setState({
            maxCityCardHeight: extendedHeight,
            topFiveDoctorCardHeight: extendedHeight,
            graphCardHeight: extendedHeight
        });
    };

    navigateTo = (type) => {

        if (type == 'order_management') {
            this.props.setActiveMenuItem('ordermanagment');
            this.props.setActiveDashboardClick('');
            this.props.navigate('/admin/order-managment');
        }
        if (type == 'registration') {
            this.props.setActiveMenuItem('ordermanagment');
            this.props.setActiveDashboardClick('registration');
            this.props.navigate('/admin/order-managment');
        }
        if (type == 'customer') {
            this.props.setActiveMenuItem('ordermanagment');
            this.props.setActiveDashboardClick('customer');
            this.props.navigate('/admin/order-managment');
        }

        if (type == "callcenter") {
            this.props.setActiveMenuItem('user_managment');
            this.props.navigate('/admin/user-managment/callcenter');
        }

    }

    getGraphData = (startDate = null, endDate = null) => {
        let start_date, end_date
        if (!startDate || !endDate) {
            end_date = new Date();
            start_date = new Date();
            start_date.setMonth(start_date.getMonth() - 6);
        }
        else {
            start_date = startDate
            end_date = endDate
        }
        AdminService.getGraphData({ start_date, end_date }).then(data => {
            if (data.success) {
                this.setState({ showChart: true })
            }
        })
    }

    render = () => {
        const { adminDashboardDetails, showChart, maxCityCardHeight, topFiveDoctorCardHeight, graphCardHeight } = this.state
        console.log(adminDashboardDetails, 2555989898);
        return (
            <div className="layout-wrapper">
                <AdminHeader />
                <AdminSidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {
                                !showChart && <div className="dashboardheading">
                                    <h4>Dashboard</h4>
                                </div>
                            }

                            {
                                showChart ?
                                    <ComparisonChart
                                        goBack={() => this.setState({ showChart: false })}
                                    />
                                    : <div className="row g-4">
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('registration') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Total Registrations</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.totalRegistration || 0 : 0}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Total Orders</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.totalOrders || 0 : 0}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('customer') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Total Customers</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.totalCustomers || 0 : 0}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Total Revenue</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.totalRevenue ? adminDashboardDetails.totalRevenue.toFixed(2) : "0.00" || "0.00" : "0.00"}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Total Doctors</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.totalDoctors || 0 : 0}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Total Free Pens</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.totalFreePen || 0 : 0}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div
                                                className="card card-animate"
                                                ref={this.maxCityCardRef}
                                                style={{ height: maxCityCardHeight ? `${maxCityCardHeight}px` : 'auto' }}
                                            >
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Max Order City</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />{adminDashboardDetails ? adminDashboardDetails.maxOrderCity || "" : ""}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div
                                                className="card card-animate"
                                                ref={this.topFiveDoctorCardRef}
                                                style={{ height: topFiveDoctorCardHeight ? `${topFiveDoctorCardHeight}px` : 'auto' }}
                                            >
                                                <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Top {adminDashboardDetails ? adminDashboardDetails.topFiveDoctors ? adminDashboardDetails.topFiveDoctors.length ? adminDashboardDetails.topFiveDoctors.length : 5 : 5 : 5} Doctors</p>
                                                            <h2 className="mt-2">
                                                                <span className="counter-value" data-target="28.05" />
                                                                {
                                                                    adminDashboardDetails ? adminDashboardDetails.topFiveDoctors ? adminDashboardDetails.topFiveDoctors.length ?
                                                                        adminDashboardDetails.topFiveDoctors.map((item, index) => {
                                                                            return (
                                                                                <div key={index}><p key={`${index}-txt`} style={{ fontSize: 15 }}>{item.drName}</p></div>
                                                                            )
                                                                        })
                                                                        : "" : "" : ""
                                                                }
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div
                                                className="card card-animate"
                                                ref={this.graphCardRef}
                                                style={{ height: graphCardHeight ? `${graphCardHeight}px` : 'auto' }}
                                            >
                                                <a className="" href="javascript:void(0)" onClick={this.getGraphData}><div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Order vs Registration Graph</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                <span className="counter-value" data-target="28.05" />
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <AdminFooter />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveMenuItem: (actveiveItem) => dispatch(AdminAction.setActiveMenuItem(actveiveItem)),
        setActiveDashboardClick: (activeDashboardClick) => dispatch(AdminAction.setActiveDashboardClick(activeDashboardClick)),
        setDashboardDetails: (adminDashboardDetails) => dispatch(AdminAction.setDashboardDetails(adminDashboardDetails))
    }
}

const mapStateToProps = ({ AdminReducer }) => {
    const { admin, adminDashboardDetails } = AdminReducer
    console.log(adminDashboardDetails, "adminDashboardDetails");
    return {
        adminDashboardDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Dashboard)))