import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import StockistAction from '../../../redux/actions/Stockist.action';
import * as XLSX from 'xlsx';
import { color } from 'chart.js/helpers';
class StockistManagmentDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stockistList: [],
            selectedFile: null,
            fileUploadError: '',
            imagename: '',
            isLoading: false,
            color: 'red',
            image: null,
            excelData: []
        }
        this.fileInputRef = React.createRef();

    }


    componentDidMount() {
        this.getStockistDetails();
    }

    getStockistDetails = async () => {
        try {
            this.setState({ stockistList: [] })

            let res = await AdminService.getallStockist()
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data, "lasjdhjkash");
                this.setState({ stockistList: res.data.data });
            }

        } catch (error) {

        }
    }
    handleEditStockist = async (event) => {
        event.preventDefault();
        const { stockistList } = this.state;
        const id = event.target.id;
        const selectedStockist = await stockistList.filter(item => item._id == id)
        console.log(selectedStockist, "selectedStockist");
        if (selectedStockist.length) {

            // let order_details
            //  selectedPatientDetails[0].customer['order_id'] = selectedPatientDetails[0].order_i

            // console.log(this.props.callcenterdetails, "callcender reducer")
            // this.props.setCallCenter(callCenterDetails);
            this.props.setStockist(selectedStockist[0])
        } else {
            // this.props.setCustomer(selectedPatientDetails)
        }

        this.props.navigate('/admin/user-managment/stockist/edit');
    }

    handleAdd = () => {

    }
    setOrderHistoryData = (data) => {
        let resp = []
        let statecodeString = "";
        let statenameString = "";
        for (let i = 0; i < data.length; i++) {
            if (data[i].pincodes.length > 0) {
                statecodeString = data[i].pincodes.filter(item => item.statecode).map(item => item.statecode).join(', ');
                statenameString = data[i].pincodes.filter(item => item.statename).map(item => item.statename).join(', ');
            }

            resp.push({
                _id: data[i]._id,
                name: data[i] ? data[i].name : "",
                mobileno: data[i].mobileno,
                stockist_code: data[i].stockist_code,
                statecodes: statecodeString,
                statenames: statenameString,
                email: data[i].email

            })
        }
        return resp
    }

    handleClickHome = () => {
        this.props.navigate('/admin/user-managment')
    }

    handleFileChange = async (e) => {
        const file = e.target.files[0];
        const validTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        console.log(file.size, maxSize, "file size validation");
        // Check if the file exists
        if (!file) {
            this.setState({
                isLoading: false,
                fileUploadError: 'Please upload a valid Excel file (.xls or .xlsx).',
                color: "red"
            });
            return;
        } else if (!validTypes.includes(file.type)) {
            this.setState({
                isLoading: false,
                fileUploadError: 'Please upload a valid Excel file (.xls or .xlsx).',
                color: "red"
            });

            return;
        } else if (file.size > maxSize) {
            this.setState({
                isLoading: false,
                fileUploadError: 'File size must be less than 25MB.',
                color: "red"
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheet);
                this.setState({
                    fileUploadError: '',
                    excelData: data,
                    imagename: file.name,
                    selectedFile: file
                });
            };

            reader.readAsBinaryString(file);
        }
    }
    handleUpload = async () => {
        const { excelData, selectedFile } = this.state;
        const chunkSize = 500; // Adjust chunk size depending on the data and API limits
        const totalChunks = Math.ceil(excelData.length / chunkSize);
        this.setState({ isLoading: true });
        let allChunksData = [];  // This will store all the chunked data

        if (excelData) {
            try {
                // Split the excelData into chunks
                for (let i = 0; i < totalChunks; i++) {
                    const chunk = excelData.slice(i * chunkSize, (i + 1) * chunkSize);
                    allChunksData = [...allChunksData, ...chunk];  // Accumulate chunked data

                    let payload = { excelData: chunk };

                    // Upload each chunk
                    let res = await AdminService.uploadExcelData(payload);
                    console.log(res, `Chunk ${i + 1} of ${totalChunks}`);

                    // If the upload for any chunk fails, stop and notify the user
                    if (!res.data.success) {
                        console.log(`Upload failed for chunk ${i + 1}`);
                        this.setState({
                            isLoading: false,
                            fileUploadError: 'There was an error uploading the file.',
                            color: "red"
                        });
                        // return;
                    }
                }
                console.log('3');

                // Send the Excel file to the server
                const formData = new FormData();
                formData.append('file', selectedFile);

                // Call an API to store the file on the server
                let fileRes = await AdminService.saveUploadedFile(formData);
                if (fileRes.data.success) {
                    console.log('File successfully saved on the server');
                    this.setState({
                        isLoading: false,
                        fileUploadError: 'File uploaded and saved successfully.',
                        color: "green"
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        fileUploadError: 'Error saving file on server.',
                        color: "red"
                    });
                }

            } catch (error) {
                console.log(error, "Error during chunked upload");
                this.setState({
                    isLoading: false,
                    fileUploadError: 'There was an error uploading the file.',
                    color: "red"
                });
            }
        }
    };

    //working  fine
    // handleUpload = async () => {
    //     const { excelData } = this.state;
    //     const chunkSize = 500; // Adjust chunk size depending on the data and API limits
    //     const totalChunks = Math.ceil(excelData.length / chunkSize);
    //     this.setState({ isLoading: true });
    //     this.setState(excelData);
    //     if (excelData) {
    //         console.log(11);
    //         try {
    //             // Split the excelData into chunks
    //             for (let i = 0; i < totalChunks; i++) {
    //                 console.log(12);
    //                 const chunk = excelData.slice(i * chunkSize, (i + 1) * chunkSize);
    //                 let payload = {
    //                     excelData: chunk
    //                 }

    //                 // Upload each chunk
    //                 let res = await AdminService.uploadExcelData(payload);
    //                 console.log(res, `Chunk ${i + 1} of ${totalChunks}`);

    //                 // If the upload for any chunk fails, stop and notify the user
    //                 if (!res.data.success) {
    //                     console.log(`Upload failed for chunk ${i + 1}`);
    //                     return
    //                 }

    //                 // Optionally, update progress or handle any other logic
    //             }
    //             console.log(13);
    //             // If all chunks are uploaded successfully
    //             // this.setState({ isLoading: false });
    //             this.setState({
    //                 isLoading: false,
    //                 fileUploadError: 'File uploaded successfully.',
    //                 color: "green"
    //             });
    //             console.log('File uploaded successfully in chunks');
    //         } catch (error) {
    //             console.log(error, "Error during chunked upload");
    //             this.setState({
    //                 isLoading: false,
    //                 fileUploadError: 'There was an error uploading the file.',
    //                 color: "red"
    //             });
    //         }
    //     }

    //     // console.log(excelData, "excelData");
    // }
    //end working code

    // handleUpload = async () => {
    //     const { excelData } = this.state;
    //     this.setState({ isLoading: true });
    //     if (excelData) {
    //         try {
    //             let res = await AdminService.uploadExcelData(excelData)
    //             console.log(res, 123333)
    //             if (res.data.success) {
    //                 console.log(res.data.data, "lasjdhjkash");
    //                 this.setState({ isLoading: false });
    //             }
    //         } catch (error) {
    //             console.log(error, "error");
    //             this.setState({ isLoading: false });
    //         }
    //     }

    //     console.log(excelData, "excelData");
    // }
    clearUploadFile = () => {
        this.setState({
            isLoading: false,
            fileUploadError: '',
            excelData: [],
            imagename: "",
            color: ""
        });
    }

    handleDownload = async (event) => {
        // alert(event.target.id)
        const selectOrder = event.target.id;
        const downloadUrl = config.serviceUrl + "/images/PincodeFile/pincode_excel.xlsx"; // Replace with the actual URL or path of the file
        console.log(downloadUrl, "downloadUrl");
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Excel File not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }

    };

    render = () => {
        const { stockistList, fileUploadError, color } = this.state;
        let _data = this.setOrderHistoryData(stockistList)
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-5'>
                                        <h5 className='mb-10'>Stockist Management</h5>
                                    </div>
                                    <div className='col-md-7'>
                                        <a href={void (0)} > <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                            Back
                                        </div>   </a>

                                    </div>
                                    {/* Row end */}
                                    {/* <div className='col-md-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.handleAdd}>
                                                Add
                                            </a>
                                        </div>
                                    </div> */}
                                    {/* Row end */}
                                    <div className="row g-4 mt-2">

                                        <div className="table-responsive stockist-list-table">


                                            {
                                                _data && _data.length ?
                                                    <CustomDatatable
                                                        pagination={true}
                                                        columns={[
                                                            {
                                                                name: 'Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Stockist Code',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Mobile No.',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Email',
                                                                // selector: row => row.mr_name,

                                                            },

                                                            {
                                                                name: 'State Code',
                                                                // selector: row => row.createdAt,

                                                            },
                                                            {
                                                                name: 'State Name',
                                                                // selector: row => row.createdAt,

                                                            }
                                                        ]}
                                                        data={this.setOrderHistoryData(stockistList)}
                                                        pageName="stockistmanagment"
                                                        handleEditStockist={this.handleEditStockist}



                                                    />
                                                    : <p className="norecord"><strong>No Records Found</strong></p>
                                            }
                                        </div>


                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <h5 className='mb-20'>Stockist Pincode Management</h5>
                                        </div>
                                        <div className='col-md-6'>

                                        </div>

                                        <div className="col-md-6">
                                            <div className="me-4">
                                                <input
                                                    type="file"
                                                    ref={this.fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={this.handleFileChange}
                                                    onClick={(event) => {
                                                        event.target.value = null
                                                    }}
                                                />
                                                <button type="button" className="btn btn-white form-control upload-btn" onClick={() => {
                                                    console.log(this.fileInputRef);
                                                    this.fileInputRef.current.click()
                                                }}>
                                                    Upload All India Pin Code vs CNF Pin Code Excel File
                                                </button>
                                                <p className="text-center" style={{ fontSize: 11, paddingTop: 10, textAlign: 'center', color: "#0a4f73", marginRight: 5, float: 'left', marginLeft: 5 }}> Note: Please upload Excel files only within a maximum file size of 25MB. </p>
                                                {fileUploadError && <div style={{ color, textAlign: 'center', marginRight: 0, float: 'left' }}>{fileUploadError}</div>}
                                                {this.state.imagename != "" && <p style={{ fontSize: 12, marginTop: 5, float: 'left' }}>Selected file: {this.state.imagename}</p>}
                                            </div>
                                            <div className='col-md-2'>

                                            </div>
                                        </div>
                                        <div className='col-md-6 actionbutton'>
                                            <div className='d-flex'>
                                                <a href={void (0)} > <div className="btn-home flotleft mx-2" onClick={this.handleUpload}>
                                                    Submit
                                                </div>   </a>

                                                <a href={void (0)} > <div className="btn-home" onClick={this.clearUploadFile}>
                                                    Clear
                                                </div>   </a>
                                                <a href="/assets/files/Pincode_Sample.xlsx" > <div className="btn-home mx-2" style={{ width: 50 }} title='Download Sample File' download target="_blank">
                                                    <i class="fa fa-download" aria-hidden="true"></i>
                                                </div>   </a>

                                                <a href={void (0)} > <div className="btn-home" style={{ width: 50 }} title='Download Uploaded Excel File' onClick={this.handleDownload}>
                                                    <i class="fa fa-download" aria-hidden="true"></i>
                                                </div>   </a>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div >

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
        setStockist: (stockistdetails) => dispatch(StockistAction.setStockist(stockistdetails))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(StockistManagmentDashboard)))