import {
  SAVE_PRESCRIPTION,
  SET_CUSTOMER,
  SET_CONSENT_CHECKBOX,
  CLEAR_CONSENT_CHECKBOX,
  CLEAR_CUSTOMER_STATE,
  SET_PAGE_NAME
} from '../types/Customer.type'

const initState = {
  customer: null,
  prescription: null,
  token: null,
  consentValue: null,
  pagename: null
}

export default (state = initState, action) => {
  const { type, payload } = action

  console.log(action, "action125666");
  switch (type) {
    case SET_CUSTOMER:
      return Object.assign({}, state, { customer: payload })
    case SAVE_PRESCRIPTION:
      return Object.assign({}, state, { prescription: payload })
    case SET_CONSENT_CHECKBOX:
      return Object.assign({}, state, { consentValue: payload })
    case SET_PAGE_NAME:
      return Object.assign({}, state, { pagename: payload })
    case CLEAR_CONSENT_CHECKBOX:
      return Object.assign({}, state, { consentValue: null })
    case CLEAR_CUSTOMER_STATE:
      return Object.assign({}, initState)
    default:
      return state
  }
}