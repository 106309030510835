import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  // DOCTOR_LOGIN_API,
  DOCTOR_PERSONA_DETAIL,
  DOCTOR_PERSONA_INFO
} from './Slugs'
import DoctorAction from '../redux/actions/DoctorPersona.action';
class DoctorService extends Base {
  constructor(props) {
    super(props)
  }
  // doctorLogin(data) {
  //   return this.post(DOCTOR_LOGIN_API, data);
  // }
  getdoctorPersonaDetails(data) {
    return this.post(DOCTOR_PERSONA_DETAIL, data);
  }
  getdoctorPersonaInfo(data) {
    return this.post(DOCTOR_PERSONA_INFO, data);
  }

}

export default new DoctorService()