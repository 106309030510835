import React, { Component } from 'react'

class Concent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () => {
    return (
      <section className="privacy-policy-section mt-5">
        <div className="container">
          <div className="privacy-policy-container">
            <div className="card border-0 privacy-content-card">
              <div className="card-body px-0">
                <p className="common-paragraph">
                  1.	I have been provided with the Diabetes golden forum Privacy Policy which is also available on its website (available at: DGF weblink ), and have understood the contents thereof.</p>
                <p> 2.	I understand that my personal data may be collected only with my valid consent, and that it may be used or processed only in accordance with the Privacy Policy issued by Wockhardt.</p>
                <p>3.	I understand that my personal data is collected for the following purposes :
                  <p>•	To enable your access to Our Site or App to provide you Services;</p>
                  <p>•	We use the information that you provide for such purposes such as responding to your requests.</p>
                  <p>•	For non-targeting reasons such as frequency capping, compliance, billing, ad reporting or delivery, market research or product development purposes;</p>
                  <p>•	To comply with applicable law;</p>
                  <p>•	To conduct audits and quality assessment procedures;</p>
                  <p>•	To analyse the use of our resources, troubleshooting problems and improving Our Products and Services, by using the information regarding your mobile device and software.</p>
                  <p>•	Contacting users, both during and after an order, for updates, resolution of queries, order details, consultations, follow-up consultations or offering new products or services;</p>
                  <p>•	To investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of Our Terms, or as otherwise required by law;</p>
                  <p>•	To respond to any queries that you may have, and to communicate information to you, including notifications of any promotions or alerts, any changes/updates to the Site, or the introduction of any future fees or charges that We may collect at the time for purchasing products or provision of Our Services to you; or</p>
                  <p>•	To contact you, by way of SMS, email and phone calls, from time to time to record your valuable feedback on Our products and Services, as they currently stand, and/or any potential products and services that may be offered in the future.</p>
                  <p>•	We may use “cookies” information and “automatically collected” information We collect on the Platform to (I) personalize Our services, such as remembering your information so that you will not have to re-enter it during your visit or the next time you avail the Service; (ii) provide customized advertisements, content, and information; (iii) monitor and analyse the effectiveness of the Service and third-party marketing activities; (iv) monitor aggregate site usage metrics such as total number of visitors and pages viewed; and (v) track your entries, submissions, and status in any promotions or other activities on the Service.</p>
                  <p>•	We may access or store your information if it is necessary to detect, prevent or address fraud and other illegal activity or to protect the safety, property or rights of the Platform or others.</p>
                  <p>•	We may use information regarding your location or the location of your device through which you access the Service for a number of purposes, including without limitation to confirm you are located in a jurisdiction in which the Service is offered and to identify an appropriate Medical Practitioner.</p>
                  <p>•	We may collect, analyse, use, publish, create and sell de-identified information, of which your personal or sensitive Personal Data might be a component, for any business or other purpose not prohibited by applicable law, including for research and marketing purposes. (Collectively “Purposes”).</p>
                  <p>4.	I understand that my personal data may be shared with various other entitiesonly with my consent.</p>
                  <p>5.	I understand that I have certain rights that I enjoy as a data principal over my personal data, in relation to its collection, use and processing. These rights relate to confirmation and access, correction and erasure, objection of disclosure, and portability of my personal data. These rights are available to me in the manner set out in the Privacy Policy issued by Wockhardt.</p>
                  <p>6.	I understand that I have the right to revoke my consent at any time in the manner specified under the Privacy Policy.</p></p>

                <p>Having read and understood the points mentioned above, I consent to allowing Wockhardt to collect, use, share or process such personal data as may be necessary to provide services availed by me.</p>
                <p>I understand and agree to Wockhardt providing these services in a manner that is in accordance with the Privacy Policy any other policy that may be notified from time to time for the implementation of these abovementioned policies, and any other law that may be applicable.</p>


              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Concent