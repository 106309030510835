import { store } from "../redux/index.store";
export const loginGuard = (to, from, next) => {
    const { CommonReducer } = store.getState();
    const token = CommonReducer.token;
    console.log(token, 'loginGuard');
    if (token) {

        console.log(token, 'Authenticated - Proceed');
        next();  // Allow the navigation without redirection
    } else {
        console.log(token, 'Not authenticated - Redirecting');

        // If the user is trying to access registration without a token, prevent access
        if (to.path === '/registration') {
            console.log('Trying to access registration without token');
            next('/');  // Redirect to login or home if not authenticated
        } else {
            // Redirect to the homepage or login if not authenticated
            next("/");
        }
    }
};
export const userLoginGuard = (to, from, next) => {
    const { CommonReducer } = store.getState();
    const token = CommonReducer.token;

    console.log(to.path, from.path, 99999)

    console.log(token, 'loginGuard');
    if (token) {
        console.log(token, 'Authenticated - Proceed');
        next();
    } else {
        next("/user");
    }
};




export const customerLoginGuard = (to, from, next, route) => {
    console.log('customerLoginGuard triggered');
    const { CommonReducer } = store.getState();
    const token = CommonReducer.token;
    // alert('customerLgin');
    // alert(token);
    console.log(token, 111133);
    if (!token) {
        console.log(1114);
        next("/")
    }
    else {
        console.log(1115);

        next();
    }
}

export const mrLoginGuard = (to, from, next, route) => {
    const { CommonReducer } = store.getState();
    const token = CommonReducer.token;
    // alert(JSON.stringify(token))
    if (!token) {
        console.log(1116);
        next("/")
    }
    else {
        console.log(1117);
        next();
    }
}