import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../../components/Reusable/WithRouter';
import config from '../../../../config/emrok.config';
import Common from '../../../../hoc/Common.hoc';
import moment from 'moment';
import AdminHeader from "../../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../../components/layout/Admin/AdminFooter";
import '../../../../assets/css/admin/style.css';
import '../../../../assets/css/admin/responsive.css';
import AdminService from '../../../../services/Admin.service';
import CustomDatatable from "../../../../components/Datatable/CustomDatatable.component";
import AdminAction from '../../../../redux/actions/Admin.action';
import AdminReducer from '../../../../redux/reducers/Admin.reducer';
class DoctorPersonaList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      doctorPersonaList: []
    }
  }

  componentDidMount() {
    this.geDoctorPersonaList();
  }

  geDoctorPersonaList = async () => {
    try {
      this.setState({ doctorPersonaList: [] })
      let res = await AdminService.getDoctorPersonaList()
      if (res.data.success) {
        this.setState({ doctorPersonaList: res.data.data.data });
      }
    } catch (error) {
    }
  }

  handleEditDoctorPersona = async (event) => {
    event.preventDefault();
    const { doctorPersonaList } = this.state;
    const doctor_persona_id = event.target.id;
    const selectedRecord = await doctorPersonaList.filter(item => item._id == doctor_persona_id)
    console.log(selectedRecord, "selectedRecord");
    if (selectedRecord.length) {
      this.props.setDoctorPersonaDetails(selectedRecord[0])
    } else {
      // this.props.setCustomer(selectedPatientDetails)
    }
    this.props.navigate('/admin/user-managment/doctor-persona/edit');
  }

  setOrderHistoryData = (data) => {
    let resp = []
    for (let i = 0; i < data.length; i++) {
      resp.push({
        _id: data[i]._id,
        name: data[i].name,
        email: data[i].email,
        mobileno: data[i].mobileno,
        status: data[i].status
      })
    }
    return resp
  }
  handleAdd = () => {
    this.props.navigate('/admin/user-managment/doctor-persona/add');
  }

  removeDoctorPersona = (event) => {
    if (event.target.id != null) {
      this.props.hoc.customAlert('Are you sure you want to remove?', false, "", true, true, async () => {
        let payload = {
          id: event.target.id
        }
        let res = await AdminService.removeDoctorPersona(payload)
        console.log(res, 123333)
        if (res.data.success) {
          this.props.hoc.customAlert('Record deleted successfully', true);
          this.geDoctorPersonaList();
        }
      }, () => {

      })
    } else {
      this.props.hoc.customAlert('Something went wrong', false)
    }


  }

  handleClickHome = () => {
    this.props.navigate('/admin/user-managment')
  }

  render = () => {
    const { doctorPersonaList } = this.state;
    let _data = this.setOrderHistoryData(doctorPersonaList)
    console.log(_data, "aksdhklsah");
    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className='col-md-6'>
                    <h5 className='mb-20'>Doctor Persona Management</h5>
                  </div>
                  <div className='col-md-6'>
                    <a href={void (0)} > <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                      Back
                    </div>   </a>
                    <a href={void (0)}>
                      <div className="btn-home flotright" onClick={this.handleAdd}>
                        Add
                      </div>
                    </a>
                  </div>
                  {/* Row end */}
                  <div className="row g-4">
                    <div className="table-responsive">
                      {
                        _data && _data.length ?
                          <CustomDatatable
                            pagination={true}
                            columns={[
                              {
                                name: 'Name',
                                // selector: row => row.mr_name,

                              },
                              {
                                name: 'Email',
                                // selector: row => row.mr_name,

                              },
                              {
                                name: 'Mobile No.',
                                // selector: row => row.mr_name,

                              },
                              {
                                name: 'Status',
                                // selector: row => row.status,

                              },
                              {
                                name: 'Action',
                                // selector: row => row.createdAt,

                              }
                            ]}
                            data={this.setOrderHistoryData(doctorPersonaList)}
                            pageName="doctor_persona_managment"
                            handleEditDoctorPersona={this.handleEditDoctorPersona}
                            removeDoctorPersona={this.removeDoctorPersona}
                          />
                          : <p className="norecord"><strong>No Records Found</strong></p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    // setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails))
    setDoctorPersonaDetails: (doctorPersonaDetails) => dispatch(AdminAction.setDoctorPersonaDetails(doctorPersonaDetails)),
  }
}

const mapStateToProps = (state) => {
  return {
    OrderReducer: state.OrderReducer,
    CustomerReducer: state.CustomerReducer,
    CommonReducer: state.CommonReducer,
    AdminReducer: state.AdminReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorPersonaList)))