import React from "react";
import LogoutIcon from "../../../assets/Svg/LogoutICon";
import CommonAction from "../../../redux/actions/Common.action";
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import OrderAction from "../../../redux/actions/Order.action";
import CustomerAction from "../../../redux/actions/Customer.action";
import FeedbackAction from "../../../redux/actions/Feedback.action";
import MrAction from "../../../redux/actions/Mr.action";
import DoctorAction from "../../../redux/actions/DoctorPersona.action";
import StockistAction from "../../../redux/actions/Stockist.action";
import AdminAction from "../../../redux/actions/Admin.action";
export default function StockistHeader() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = () => {

        dispatch(CallCenterAction.logout());
        dispatch(CommonAction.logout());
        dispatch(CustomerAction.clearState());
        dispatch(FeedbackAction.clearState());
        dispatch(OrderAction.clearState());
        dispatch(MrAction.logout());
        dispatch(DoctorAction.logout());
        dispatch(StockistAction.logout());
        dispatch(AdminAction.logout());
        // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
        const url = "/user";
        navigate(url)
    }
    return (
        <>
            <div className="row">
                <div className="w-100 logo-top-box mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                            <a href="javascript:void(0);"><img src={require("../../../assets/images/dgf-logo.png")} alt="logo" /></a>
                        </div>
                        <div>
                            <button type="submit" className="btn-logout" onClick={() => { logout() }}>
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}