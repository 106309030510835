import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { connect } from 'react-redux';
import './ComparisonChart.style.css';

class ComparisonChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            options: null,
            goBack: undefined
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            goBack: props.goBack || undefined,
            data: props.graphData || null,
            options: props.graphOptions || null
        };
    }

    componentDidMount = () => {
        ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    };

    render() {
        const { data, options } = this.state;
        return (
            <div className="chart-container">
                <div className="col-md-12">
                    <div className="btn-home flotright">
                        <a href="javascript:void(0);" onClick={() => this.state.goBack && this.state.goBack()}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-home"
                            >
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            Back
                        </a>
                    </div>
                </div>
                {data && options && (
                    <div className="chart-wrapper">
                        <Bar data={data} options={options} />
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = ({ AdminReducer }) => {
    const { admin, adminDashboardDetails, graphData, graphOptions } = AdminReducer;
    return {
        adminDashboardDetails,
        graphData,
        graphOptions
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonChart);
